/* Topbar starts here */


.topbar{
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 89;
    background-color: #f7f7f7;

}

.topbar h1{
    color: #333;
    font-size: 22px;
    cursor: pointer;
}


.download-btn button{
    border-radius: 50px;
    background-color: #8dc53e;
    border: none;
    outline: none;
    padding: 10px 20px;
    color: #fff;
    cursor: pointer;
    transition: .6s;
}

.download-btn button:hover{
    background-color: #71aa20;
}

@media screen and (max-width:800px) {
    .topbar{
        flex-direction: column;
        justify-content: center;
        height: 120px;
    }
    .topbar h1{
        margin-bottom: 7px;
    }
}

/* Topbar ends here */

/* Hero Tab starts here */
.hero-tab{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 322px;
}
.hero-tab .hero-bg{
    background-color: #0d47a1;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100vw;
    height: 322px;
    z-index: 1;
    overflow: hidden;
    
}

.particles{
    position: relative;
    height: 300px;
    z-index: 2;
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.hero-tab h1{
    color: #fff;
    position: relative;
    z-index: 5;
    padding-top: 60px;
    margin-bottom: 0;
    font-size: 40px;
    font-weight: 700;
}
.hero-tab p{
    color: #fff;
    position: relative;
    z-index: 5;
    margin-top: 10px;
    font-style: italic;
    font-size: 14px;
}

@media screen and (max-width:800px) {
    .hero-tab .hero-bg{
        position: absolute;
        top: 120px;
        left: 0;
        width: 100vw;
        height: 322px;
        z-index: 1;
    }
}
/* Hero ends here */

/* summary starts here */
.sum-tab{
    position: relative;
    background-color: #f7f7f7;
    border: 10px solid #fff;
    padding: 40px 40px 45px;
    text-align: center;
    box-shadow: 0 2px 15px rgb(0 0 0 / 11%);
    top: -115px;
    margin-bottom: -50px;
    z-index: 7;
}

.tab-icon{
    position: absolute;
    transform: translate(-50%, -50%);
    top: -10px;
    left: 50%;
    font-size: 18px;
    background-color: #47b4e5;
    color: #fff;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.summary-body{
    line-height: 24px;
    width: 80%;
    margin: 0 auto;
}

@media screen and (max-width:800px) {
    .summary-body{
        width: 99%;
    }

    .sum-tab{
        padding: 40px 10px 45px;
    }
}


/* Summary ends here */

/* Project starts here */

.projectlist{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 0 auto;
    align-items: flex-start;
}

.project{
    margin: 10px 10px 30px;
    width: 270px;
}

.project-img{
    /* width: 33%; */
    height: 350px;
    background-color: #8dc53e;
    border: 5px solid #71aa20;
    border-radius: 8px;
    overflow: hidden;
}

.project-img img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: center;
}

.project ul{
    text-align: center;
    margin: 0 auto 30px;
    padding-left: 0;
}

.project ul li{
    list-style: none;
    margin: 10px 0;
}

.project a{
    outline: none;
    border: none;
    background-color: #8dc53e;
    padding: 10px 15px;
    cursor: pointer;
    color: #fff;
    transition: 1s;
    text-decoration: none;
}

.project a:hover{
    background-color: #71aa20;
}

/* @media screen and (max-width:800px) {
    .projectlist
} */

/* Project ends here */

/* experience starts here */
.exp-tab{
    position: relative;
    background-color: #f7f7f7;
    border: 10px solid #fff;
    padding: 40px 40px 45px;
    text-align: center;
    box-shadow: 0 2px 15px rgb(0 0 0 / 11%);
    z-index: 7;
    margin-bottom: 50px;
} 

.tab-heading{
    text-transform: uppercase;
}

.exp-tab .tab-icon{
    background-color: #5acdbb;
}

.work-list{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: 12px;
    /* letter-spacing: 0.2px; */
}

.work-list .work-title{
    flex: 0.6;
    text-align: right;
    padding-top: 12px;
    margin-right: 30px;
}

.work-list .work-points{
    text-align: left;
    flex: 1.4;
    border-left: 1px solid #5acdbb;
    position: relative;
}

.work-title-left{
    display: none;
}

.work-date{
    color: #5acdbb;
    font-size: 14px;
}

.work-place{
    font-weight: 700;
}

.work-post{
    font-weight: 300;
    font-style: italic;
}


.work-ul::before{
    content: "";
    background-color: #5acdbb;
    width: 10px;
    border-radius: 50%;
    height: 10px;
    position: absolute;
    top: -5px;
    left: -6px;
}
.work-ul::after{
    content: "";
    background-color: #5acdbb;
    width: 10px;
    border-radius: 50%;
    height: 10px;
    position: absolute;
    bottom: -5px;
    left: -6px;
}

.locate{
    font-style: normal;
    font-size: 11px;
}


.work-ul li{
    margin-bottom: 20px;
}

@media screen and (max-width:800px) {
    .work-title{
        display: none;
    }

    .exp-tab{
    padding: 40px 10px 45px;
    }

    .work-title-left{
        display: block;
        margin-left: 30px;
    }
}

/* Experience ends here */

/* certifications starts here */
.cert-tab{
    position: relative;
    background-color: #f7f7f7;
    border: 10px solid #fff;
    padding: 40px 40px 45px;
    text-align: center;
    box-shadow: 0 2px 15px rgb(0 0 0 / 11%);
    z-index: 7;
    margin-bottom: 50px;
}

.cert-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
}

.cert-tab .tab-icon{
    background-color: #fad049;
}

.cert-box{
    width: 20%;
    margin: 10px 10px;
    padding: 30px 40px 35px;
    text-align: center;
    box-shadow: 0 2px 15px rgb(0 0 0 / 11%);
    background-color: #fff;
    transition: .6s;
    cursor: pointer;
}

.cert-box:hover{
    box-shadow: none;
}

.cert-box .cert-title{
    font-weight: 400;
}

.cert-box p{
    margin: 5px auto 10px;
}

.cert-date{
    font-size: 12px;
    font-weight: 400;
}

.cert-sch{
    color: #fad049;
    font-style: italic;
}

@media screen and (max-width:800px) {
    .cert-box{
        width: 100%;
        margin: 10px 10px;
        padding: 30px 10px 35px;
    }
}
/* certifications ends here */

/* skill starts here */
.skill-tab{
    position: relative;
    background-color: #f7f7f7;
    border: 10px solid #fff;
    padding: 40px 40px 45px;
    text-align: center;
    box-shadow: 0 2px 15px rgb(0 0 0 / 11%);
    z-index: 7;
    margin-bottom: 50px;
}
.skills-list{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
}

.skill-tab .tab-icon{
    background-color: #ec5082;
}

.skill{
    background-color: #fff;
    box-shadow: 0 2px 15px rgb(0 0 0 / 11%);
    display: flex;
    align-items: center;
    margin: 10px;
    padding: 10px 0;
    width: 310px;
    transition: .5s;
    cursor: pointer;
}

.skill:hover{
    box-shadow: none;
}


.skill span{
    margin: 10px;
    color: #ec5082;
    font-weight: 700;
}


/* skill ends here */

/* Footer starts here */
.footer-tab{
    position: relative;
    padding: 40px 40px 5px;
    text-align: center;
    z-index: 7;
    color: #fff;
}
.footer-bg{
    transform: translate(-50%, -50%);
   position: absolute;
    width: 100vw;
    bottom: -70%;
    left: 50%;
    height: 352px;
    z-index: 5;
    background-color: #333;
}

.footer-tab .tab-heading{
    position: relative;
    z-index: 19;
}

.footer-sections{
    display: flex;
    position: relative;
    z-index: 9;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer-sections .footer-links{
    height: 150px;
    width: 32%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #333;
}

.footer-links a{
    color: #333;
    cursor: pointer;
    margin-bottom: 15px;
}

.footer-sections .footer-links:first-child{
    background-color: #000;
    color: #fff;
}

.footer-links p{
    margin: 0;
}

.foot-copy{
    width: 100%;
    text-align: center;
    padding: 10px auto;
    font-size: 12px;
    color: #fff;
    position: relative;
    z-index: 9;
    padding-top: 20px;
}

@media screen and (max-width:800px) {
    .footer-sections{
        width: 100% ;
        flex-direction: column;
    }

    .footer-links{
        width: 100% !important;
        margin: 10px;
    }

    .footer-bg{
        bottom: -50%;
        height: 552px;
    }
    .footer-tab .tab-heading{
        color: #333;
    }
}
/* footer ends here */